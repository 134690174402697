/* .custom__line{
    color: #295180;
    width: 500px;
    border: 2px solid #295180;
    text-align: center;
} */
.custom__div{
    display: flex;
    justify-content: center;
}

.custom__line{
    width: 15vw;
    height: 2px;
    background-color: rgb(15, 165, 35);
}

.custom__uspline{
    width: 11vw;
    height: 2px;
    background-color: rgb(15, 165, 35);
}

.custom__textline{
    width: 29vw;
    height: 2px;
    background-color: rgb(15, 165, 35);
}

/* ********CARD SECTION********* */

.card__section{
    background-image: url(../../assests/bg-card.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.card__bg{
    background-color: rgba(35, 33, 43, 0.486);
    padding: 60px 0px;
}

.card__container{
    display: flex;
    justify-content: space-around;
    /* justify-content: space-between; */
}

.outer__box{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border: 1px solid black; */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.295);
    border-radius: 20px;
    /* padding: 50px; */
    padding: 50px 20px;
    background-color: #A2CDF4;
}

.bg-small1{
    background-image: url(../../assests/bg-small1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}
.bg-small2{
    background-image: url(../../assests/bg-small2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}
.bg-small3{
    background-image: url(../../assests/bg-small3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

.bg-small4{
    background-image: url(../../assests/bg-small4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

.inner__text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inner__text h5{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: white;
}

.card__btn{
    background-color: rgb(15, 165, 35) ;
    color: white;
    /* padding: 6px 15px; */
    padding: 6px 10px;
    border: 1px solid rgb(15, 165, 35);
    border-radius: 7px;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.253);
}

.card__btn:hover{
    background-color: #3a3c40;
    border: 1px solid #3a3c40 ;
}

.inner__img:hover{
    transition: 0.5s ease-in-out;
    transform: scale(1.2);
}


@media screen and (max-width: 700px) {
    .outer__box{
        justify-content: space-between;
    }
    .inner__text h5{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
    .inner__img{
        padding-left: 20px;
    }
    .card__btn{
        padding: 4px 8px;
    }
}

/* *********FILTER SECTION*********** */

.filter__section{
    padding: 60px 0;
}

.category{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 1.875rem;
    margin-top: 50px;
    font-weight: 600;
}

.category__name{
    background-color: #152e4a;
    padding: 5px 13px;
    border-radius: 19px;
    color:white;
    margin-top:10px;
}

.category__name:hover{
    cursor: pointer;
    color: yellow;
}

.active__state{
    color: yellow;
}

.card-body{
    background-color: #152e4a;
    color: white;
}

.card-text{
    color:yellow;
}

.unround__border{
    border-radius: 0 !important;
}

@media screen and (max-width: 700px) {
    .card__body{
        padding: 0 35px;
    }
}


/* *********USP SECTION***********/

.home__usp{
    background-color: #DDDAEC;
    padding: 60px 0;
}
.icon__card{
    /* border: 1px solid black; */
    padding: 27px;
}

.usp__text{
    font-weight: 200;
}

