/* <========== Google Fonts ===========> */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Rubik&family=Work+Sans:wght@300&display=swap');

*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: medium; */
  /* font-size: small; */
}

/* body p{
  color: rgb(59, 57, 57);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* **********HERO SECTION********* */
.hero__inner{
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.breadcrumb-item{
  color: white !important;
}

/* ********SPAN COLOR************** */

.red__color{
  color: red;
  font-weight: 600;
}

.darkblue__color{
  color: darkblue;
}
/* 
h3::first-letter {
color: red;

} */

.trade__mark{
  font-weight: 400 !important;
  font-size: larger;
}