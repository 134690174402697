/* ***************ABOUT HERO***** */

.hero__contact{
    width: 100%;
    /* height: 40vh; */
    background-image: url('../../assests/slider_3.png');
    /* background-position: center; */
}

/* *********CONTACT CARD******** */
.contact__section{
    padding: 60px 0;
}

.concard__container{
    display: grid;
    grid-template-columns: repeat(3,1fr); 
}

.custom__concard{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
    margin: 0 10px;
    box-shadow: 0px 0px 19px #ccc;
}

.custom__concard span{
    font-size: 25px;
}

@media screen and (max-width: 700px) {
    .concard__container{
        grid-template-columns: 1fr;
    }

}