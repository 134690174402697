* {
  margin: 0;
  padding: 0;
}

.container__topbar {
  /* background-color: #071D37; */
  background-color: #ef3e36;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: "Work Sans", sans-serif;
}

.topbar__left {
  margin-left: 45px;
}

.topbar__left span {
  margin-left: 10px;
}

.topbar__right {
  margin-right: 90px;
}
.topbar__right span {
  margin: 7px;
}

@media screen and (max-width: 700px) {
  .container__topbar {
    display: none;
  }
  /* .container__topbar{
        justify-content: center;
    }
    .topbar__right{
        margin-right: 0;
    }
    .topbar__left{
        display: none;
    } */
  /* .nav__menu{
        display: none;
    } */
}

/* .container__nav{
    background-color: #152e4a;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav__logo{
    margin-left: 90px;
}

.nav__menu{
    margin-right: 70px;
    margin-top: 25px;
}

.nav__menu ul{
    display: flex;
}

.nav__menu ul li{
    color: white;
    list-style: none;
    margin: 0 30px;
    cursor: pointer;
}

.nav__menu ul li a{
    text-decoration: none;
    color: white;
}

.nav__menu ul li a:hover{
    color: rgb(15, 165, 35);
}

.nav__toggle{
    position: fixed;
    top: 1.25rem;
    left: 1.875rem;
    cursor: pointer;
    height: 40px;
    width: 45px;
    background-color: white;
    border: 1px solid #e8dfec;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: .3s;
}

.aside{
    position: fixed;
    left: 0;
    top: 0;
    background: #0a014f;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 300px;
    min-height: 100vh;
    display: none;
    flex-direction: column;
    z-index: 10;
    transition: .3s;
}

.aside__logo{
    margin-left: 18px;
    margin-bottom: 25px;
}

.aside__item{
    color: white;
    list-style: none;
    padding-top: 10px;
}

.aside__list a{
    text-decoration: none;
}

@media screen and (max-width: 700px) {
    .aside {
        left: -300px;
        display: flex;
    }

    .nav{
        display: none;
    }

    .nav__toggle{
        display: flex;
    }

    .nav__toggle-open{
        left: 230px;
        z-index: 20;
    }

    .show-menu{
        left: 0;
    }

    .nav__logo{
        width: 100%;
        display: flex;
        margin-left: 0;
        justify-content: center;
    }
    .nav__logo{
        margin-left: 50px;
    }

}
 */

.nav__custom {
  background-color: #152e4a !important;
}

.nav__link {
  color: white;
  margin: 0 10px !important;
}
.nav__link:hover {
  color: rgb(15, 165, 35);
}
.nav-link:focus {
  color: rgb(15, 165, 35) !important;
}
