.mgt__txt{
    /* border: 1px solid rgba(0, 0, 0, 0.199); */
    border-radius: 20px;
    /* padding: 0 50px; */
}

.mgt__txt p{
    background-color: antiquewhite;
    padding: 30px;
    border-radius: 20px;
}

.mob-view{
    display: none;
}

@media screen and (max-width: 770px){
    .desk-view{
        display: none;
    }
    .mob-view{
        display: block;
    }
}

.mgt-abt{
    color: red;
}