.custom__aboutline{

        width: 12vw;
        height: 2px;
        background-color: rgb(15, 165, 35);
}

/* ***************ABOUT HERO***** */

.hero__about{
    width: 100%;
    /* height: 40vh; */
    background-image: url('../../assests/slider_3.png');
    /* background-position: center; */
}

/* *********ABOUT PROFILE********* */
.profile__about{
    padding: 60px 0;
    /* font-weight: 400;
    font-size: small; */
}

.profile__outer{
    text-align: justify;
}

/* **********ABOUT BOXES******* */
.card__flex{
    display: flex;
    flex-direction: row;
}

.card__about{
    /* padding: 60px 0; */
    padding-bottom: 60px;
    font-size: small;
    /* font-family: 'Work Sans', sans-serif; */
}

.card__flex{
    display: flex;
}

.left__box{
    /* border: 1px solid black; */
    padding: 30px;
    text-align: justify;
    background-color: #5FA8D3;
    color: white;
}

.right__box{
    /* border: 1px solid black; */
    padding: 30px;
    text-align: justify;
    color: white;
    background-color: #152E4A;
}

@media screen and (max-width: 750px) {
.card__flex{
    flex-direction: column;
}
}

/* **********ABOUT VALUES********** */
.values__about{
    padding: 30px 0;
    text-align: justify;
}

.values__flex{
    display: flex;
    flex-direction: column;
}