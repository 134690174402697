footer{
    background-color: #152E4A;
    color: white;
    padding-top: 70px;
}

.footer__menu{
    list-style: none;
}

.footer__menu li a{
    text-decoration: none;
    color: white;
}

.footer__menu li a:hover{
    color: rgb(15, 165, 35) !important;
}

.footer__bottom{
    margin-top: 50px;
    background-color: #071D37;
    padding: 10px 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: 200;
    font-size: small;
}

.social-icons a{
    text-decoration: none !important;
}
.social-icons a i{
    font-size: 20px;
}